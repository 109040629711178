import React, { useContext } from "react"
import Layout from "../components/layout"
import { useStoryblokState } from "gatsby-source-storyblok"
import { StoryblokComponent } from "gatsby-source-storyblok"
import { graphql, useStaticQuery } from "gatsby"
import LastBanner from "../components/LastBanner"
import { parse } from "../utils/helper"
import { GlobalContext } from "../context"
import SideBanner from "../components/FormSecondary"
import BlogFooterSection from "../components/BlogFooterSection"
import Subscribe from "../components/SubscribeSection"
export default function PageIndex({ pageContext, location , data:pageData}) {
  const story = useStoryblokState(pageContext.story, location)
  const { content } = story || {}
  const { crumbLabel, breadcrumbs } = content || {}
  const { data: dt } = useContext(GlobalContext)
  const { lang: language } = dt
const data = pageData?.data
const blog_layout = parse(pageData?.blog_layouts?.edges.find((i) => i.node.lang === language)?.node?.content)
  // const { data } = useStaticQuery(graphql`
  //   query last_banner_blog {
      
  //   }
  // `);
  const blok =
    parse(
      data?.edges?.find((i) => i?.node?.lang === language)?.node?.content
    ) || {};
      const blog_data = Object.assign((story?.content ?? {}),{ blog_layout : blog_layout} )
  return (
    <Layout
      extra_className="blogDetail"
      location={location}
      full_slug={story?.full_slug}
      lang={story?.lang}
      breadcrumbs={breadcrumbs}
      meta={{
        ...story?.content?.Meta,
        tracking_variable: story?.content?.tracking_variable,
      }}
      pathname={pageContext.pathname}
      extra_content={blog_data}
      persist_header_state
    >
      <>
        <StoryblokComponent
          blok={blog_data}
          location={location}
          crumb={crumbLabel}
          pathname={pageContext.pathname}
        />
      </>
      <LastBanner blok={blok} />
      {/* <Subscribe/> */}
      <BlogFooterSection/>
    </Layout>
  );
}

export const query=graphql`{
  data: allStoryblokEntry(
        filter: { field_component: { eq: "Last Banner Section" } }
      ) {
        edges {
          node {
            id
            name
            slug
            field_component
            full_slug
            content
            internalId
            lang
          }
        }
      }
  blog_layouts: allStoryblokEntry(
      filter: { field_component: { eq: "blog_layout" } }
    ) {
      edges {
        node {
          id
          internalId
          content
          lang
        }
      }
    }
}
`