import React from "react";
import "./author-section.scss";
import { Image } from "../../utils/helper";

const AuthorSection = ({ blok }) => {
  // console.log(block.block,"9090909");
  const author = blok?.section_footer
    ? blok?.section_footer[0]?.Author ?? []
    : [];
  const { Author_Profile, Author_Name, Author_Role } = author[0] || {};
  return (
    <div className="blog-footer-right">
      <div className="blog-footer-author-info">
        <span className="authorInfo">
          {blok?.section_footer?.map((i) => i.Label)}
        </span>
        <div className="blog-footer-author-row">
          <div className="blog-footer-author-img">
            <Image
              src={Author_Profile?.filename ?? ""}
              alt={Author_Profile?.alt}
            ></Image>
          </div>
          <div className="blog-footer-author-details">
            <span className="authorName">{Author_Name}</span>
            <p>{Author_Role}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorSection;