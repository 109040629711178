import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { parse } from "../../utils/helper";
import AuthorSection from "../AuthorSection";
import "./blog-footer.scss";
import NewsLetterSection from "../NewsLetterSection";
import { GlobalContext } from "../../context";

const BlogFooterSection = () => {
  const { data } = useContext(GlobalContext);
  const { lang } = data;
  const footerSection = useStaticQuery(
    graphql`
      query SectionFooterQuery {
        allStoryblokEntry(
          filter: { field_component: { eq: "blog_section_footer" } }
        ) {
          edges {
            node {
              id
              content
              lang
            }
          }
        }
      }
    `
  );
  const {
    allStoryblokEntry: { edges },
  } = footerSection;
  const currentData = edges.find((i) => i.node.lang === lang)?.node;
  const formSecondary = parse(currentData?.content)

  return (
    <div className="blog-footer-section">
      <div className="container">
        <div className="blog-footer-inner">
          <NewsLetterSection blok={formSecondary} />
          <AuthorSection blok={formSecondary} />
        </div>
      </div>
    </div>
  );
};

export default BlogFooterSection;
